import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { Container, Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Contact() {
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);
  return (
    <>
      <div className="main-box" id="contact">
        <div className="box">
          <div className="contact info">
            <h3>Get In Touch With Us</h3>
            <div className="infoBox">
              <div>
                <span>
                  <FontAwesomeIcon icon={faLocationDot} />
                </span>
                <p>
                  Andhra Pradesh,
                  {/* <br /> */}
                  India
                </p>
              </div>
              <div>
                <span>
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <a href="#">+91 12122154642</a>
              </div>
              <div>
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <a href="#">felizlife@gmail.com</a>
              </div>
            </div>
            <div className="infobox2">
            <p className="iconPar">Follow Us On:</p>
            <div className="icon" data-aos="fade-up">
              <a href="" className="first_icon">
                <BsInstagram />
              </a>

              <a href="" className="second_icon">
                <BsTwitter />
              </a>

              <a href="" className="third_icon">
                {" "}
                <BsYoutube />
              </a>

              <a href="" className="fourth_icon">
                {" "}
                <BsFacebook />
              </a>
              </div>
            </div>
          </div>

          <div className="contact map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31089.68189063566!2d77.53561471190474!3d13.085858372615132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae18719e901e47%3A0xe958bfe5746782f7!2sVignana%20Kendra%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1676371258129!5m2!1sen!2sin"
              width="800"
              height="300"
              //   style="border:0;"
              allowfullscreen=""
              loading="lazy"
              //   referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contact;
