import "./Gallery.css";
import ModalImage from "react-modal-image";

import { Col, Container, Row } from "react-bootstrap";

function Gallery() {
  return (
    <Container>
      <div className="App" id="gallery">
        <h3 className="sectionTitle">Gallery</h3>

        <Row>
          <Col md={4} xs={12}>
            {" "}
            <div className="modalImage">
              <ModalImage
              small={"images/8.jpg"}
              large={"images/8.jpg"}
                
                alt="Feliz Life"
              />
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="modalImage">
              <ModalImage  
                small={"images/2.jpg"}
                large={"images/2.jpg"}
                alt="Feliz Life"
              />
            </div>
          </Col>
          <Col md={4} xs={12}>
            {" "}
            <div className="modalImage">
              <ModalImage
                small={"images/10.jpg"}
                large={"images/10.jpg"}
                alt="Feliz Life"
              />
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="modalImage">
              <ModalImage
                small={"images/7.jpg"}
                large={"images/1.jpg"}
                alt="Feliz Life"
              />
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="modalImage">
              <ModalImage
                small={"images/9.jpg"}
                large={"images/9.jpg"}
                alt="Feliz Life"
              />
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="modalImage">
              <ModalImage
                small={"images/1.jpg"}
                large={"images/1.jpg"}
                alt="Feliz Life"
              />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default Gallery;
