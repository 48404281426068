import React from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import About from "./Components/About/About";
import Products from "./Components/Products/Products";
import "bootstrap/dist/css/bootstrap.min.css";
import Contact from "./Components/Contact/Contact";
import Still_image from "./Components/Still_Image/Still_image";
import Careers from "./Components/Careers/Careers";
import Testimonials from "./Components/Testimonials/Testimonials";
import Gallery from "./Components/Gallery/Gallery";

function App() {
  return (
    <>
      <Header />
      <About />
      {/* <Testimonials /> */}
      <Still_image />
      <Products />
      <Careers />
      <Gallery/>
      <Contact />
    </>
  );
}

export default App;
