import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-scroll";

const NavHeader = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="navbar fixed-top navbar-expand-lg navbar-light bg-white"  >
      <Container>
        <Navbar.Brand href="#home">
          <img src="/images/logo.png" alt="logo" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto1">
            <Nav.Link href="#features"></Nav.Link>
            <Nav.Link href="#pricing"></Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link > 
              <li className="me-auto">
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={200}
                >
                  About
                </Link>
              </li>
            </Nav.Link>
            {/* <Nav.Link href="#deets">
              <li className="me-auto">
                <Link
                  to="testimonials"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={200}
                >
                  Testimonials
                </Link>
              </li>
            </Nav.Link> */}
            <Nav.Link >
              <li className="me-auto">
                <Link
                  to="products"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={200}
                >
                  Products
                </Link>
              </li>
            </Nav.Link>

            <Nav.Link >
              <li className="me-auto">
                <Link
                  to="careers"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={200}
                >
                  Careers
                </Link>
              </li>
            </Nav.Link>
            <Nav.Link >
              <li className="me-auto">
                <Link
                  to="gallery"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={200}
                >
                  Gallery
                </Link>
              </li>
            </Nav.Link>
            <Nav.Link >
              <li className="me-auto">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={200}
                >
                  Contact
                </Link>
              </li>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavHeader;
