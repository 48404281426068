import React, { useEffect } from "react";
import "./Header.css";
import NavHeader from "./Navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import { Col, Row } from "react-bootstrap";

const Header = () => {
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);
  return (
    <div className="banner">
      <div className="overlay">
        <NavHeader />
        <div className="banner__text " data-aos="fade-down-right">
          <h1>HAPPY LIFE</h1>
          <p id="quote">“ For Happy life through agriculture ”</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
