import './Still_image.css'
import React from "react";


const Still_image = () => {
  return (
    <div className="shop">
      <div className="container_stillimage">
        <div className="shop__text">
          <div>
            <h1>“For Happy life<br/>through agriculture”</h1>
            <div className="shop__btn">
              {/* <a href="" className="btn btn-smart">0
                SHOP NOW
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Still_image;