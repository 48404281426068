import { Col, Container, Row } from "react-bootstrap";
import "./Careers.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// import React, { useRef } from "react";
// import emailjs from "@emailjs/browser";

const Careers = () => {
  // const form = useRef();

  // const sendEmail = (e) => {
    // e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_whb2gt7",
  //       "template_lrnazx4",
  //       form.current,
  //       "ZrX06_ikdiWnMgxis"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  //   e.target.reset()
  // };

  return (
    <Container>
      <div className="mainbox" id="careers">
        <div className="mainheading">
          <h1>Careers</h1>
          <h3>Join Our Team</h3>
          <p>
            FELIZ LIFE offers excellent opportunities for deserving candidates
            at various levels in Sales. Candidates with relevant qualifications
            and experience can send their resumes to <b>hr@felizlife.in</b>{" "}
          </p>
        </div>

        <div className="career_bg">
          <div className="overlay"></div>

          <div className="mainform">
            <Form
              action="https://felizlife.in/backend/careers.php"
              method="post"
              enctype="multipart/form-data"
            >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label for="name">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label for="phonenumber">Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Phone Number"
                  name="phonenumber"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label for="email">Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label for="message">Message</Form.Label>
                <Form.Control as="textarea" rows={3} name="message" />
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload Resume</Form.Label>
                <Form.Control type="file" name="attachment" />
              </Form.Group>
              <Button
                variant="outline-success"
                className="btn"
                type="submit"
                name="submit"
              >
                Submit
              </Button>{" "}
            </Form>
            {/* <form ref={form} onSubmit={sendEmail}>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Name
                </label>
                <input
                  type="text"
                  name="user_name"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Phonenumber
                </label>
                <input
                  type="text"
                  name="user_phonenumber"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Email
                </label>
                <input
                  type="email"
                  name="user_email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Message
                </label>
                <textarea
                  name="user_email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Resume
              </label>
              <input
                name="user_phonenumber"
                class="form-control"
                type="file"
                id="formFile"
              />
            </div>
              <input
                type="submit"
                value="Submit"
                class="btn btn-outline-success"
              />

            </form> */}
          </div>
          <div></div>
        </div>
      </div>
    </Container>
  );
};
export default Careers;
