import React from "react";
import "./Product.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Col, Container, Row } from "react-bootstrap";
import { useState, useEffect } from "react";

const Products = () => {
  const [result, setData] = useState([]);
  useEffect(() => {
    fetch("https://felizlife.in/backend/fetch_products.php",{
      headers : { 
        'Content-Type': 'https://felizlife.in/backend/fetch_products.php',
        'Accept': 'https://felizlife.in/backend/fetch_products.php'
       }
    })
   
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  return (
    <Container>
      <h3 className="sectionTitle" id="products">
        Our Products
      </h3>
      <Row>
        {result &&
          result.map((product, idx) => {
            return (
              <Col className="productCol" md={4} xs={12} key={idx}>
                <div className="innerBox">
                  <img
                    src={`images/${product.pro_image}`}
                    alt="oops"
                    width="100%"
                    height="250px"
                  />
                  <p className="price__text">
                    Nullam nibh sem, imperdiet ultrices <br />
                    commodo a,vulputate vel ligula.
                    <br />
                    Duis venenatis at eros sed egestas
                    <br /> Mauris rutrum quam risus,
                    <br /> vel hendrerit dui tempor in.
                  </p>
                  <Card.Title className="product_title">
                    {product.pro_title}
                    <Card.Text>{product.pro_desc}</Card.Text>
                  </Card.Title>
                </div>
              </Col>
            );
          })}
      </Row>

      {/* <div className="Box" id="products">
        <h3>Our Products</h3>
        <div className="product-container"></div>
      </div>
      <div id="block"></div> */}
      {/* <div>
      My API <br />
      <button onClick={apiGet}>Fetch API</button>
      <br />
      <br />
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
     */}
    </Container>
  );
};

export default Products;
