import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./About.css";

const About = () => {
  return (
    <Container>
      <h3 className="sectionTitle" id="about">About Us</h3>
      <Row>
        <Col md={6} xs={12}>
          <input type={"checkbox"} id="check" className="read-more-state" />
          <h1 className="h1">Welcome To Feliz Life</h1>
          <p className="aboutDesc">
            Feliz life Science is a Manufacturer of Bio-Fertilizers and
            chemicals based out of Andhra pradesh state, Established in the year
            2017 as manufacturer of agri inputs for over two decades research of
            agriculture inputs . We are Pioneers in manufacturing of Organic
            Fertilizers and Agro chemicals based on Proteino-Lacto-gluconate
            technology and Organic plant protection product based on microbial
            extracts. Feliz supplying these products across Pan-India in all
            agriculturally important states . Our products are certified by
            Indian Organic Certification agencies...
            <span className="content">
              Feliz life Science Pvt Ltd. has taken a lead initiative in
              developing sustainable agribusiness enabled through an ‘Integrated
              Agri Policy’ covering agriculture on an end-to-end concept. Feliz
              has therefor best owed top priority to agriculture sector, framed
              various strategies like supply of quality inputs, technology
              transfer, credit etc. A majority of Population In india depends on
              Agriculture, Hence Feliz considers its motto as....
              <b> “For Happy life through agriculture ”</b>
            </span>
          </p>
          <label className="readmore" for="check">
            READ MORE
          </label>
        </Col>
        <Col md={6} xs={12} className="aboutImage">
          <img src="/images/Holdingplant.jpeg" alt="Oops" />
        </Col>
      </Row>
    </Container>
  );
};

export default About;
